import apiClient from "./http-common";

class ReferralService {
  getRewards() {
    return apiClient.get("/referral/rewards");
  }
  getStats() {
    return apiClient.get("/referral/referral-stats");
  }
  subscribers() {
    return apiClient.get("/referral/users");
  }
}

export default new ReferralService();
